
// COMPONENTS
//import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

// APP
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
	name: "UserProfile",
/*	components: {
		Dropdown3,
	},
*/
	props: {
		userId: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		let userProfileTabs = [
			{
				componentName: "ProfileDetails",
				path: `/user-profile/${props.userId}/profile-details`,
				tabName: "Overview",
				adminOnly: false,
			},
			{
				componentName: "EditProfile",
				path: `/user-profile/${props.userId}/edit-profile`,
				tabName: "Edit Profile",
				adminOnly: false,
			},
			{
				componentName: "Access",
				path: `/user-profile/${props.userId}/access`,
				tabName: "Access",
				adminOnly: true,
			},
/*			{
				componentName: "UserProjects",
				path: `/user-profile/${props.userId}/projects`,
				tabName: "Projects",
			},
*/
		];

		let user = ref(null);

		onMounted(async () => {
			setCurrentPageTitle("User Profile");

			let userProfile = await store.dispatch(Actions.USER_PROFILE, { user_id: props.userId });
			//if (userProfile != undefined && userProfile.hasOwnProperty("code") && userProfile.code == 200) {
			if (userProfile != undefined  && userProfile.code == 200) {
				user.value = userProfile.data;
			} else {
				// NO USER FOUND, RETURN BACKWARD
				router.go(-1);
			}
		});

		const isAdmin = computed(() => store.getters["userRole"] === 1);

		return {
			userProfileTabs,
			user,
			isAdmin,
		};
	},
});
